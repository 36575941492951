// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "./controllers"

const application = Application.start()

// Load all Stimulus controllers
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

document.addEventListener("DOMContentLoaded", function () {
    const cpfInput = document.getElementById("cpf-input")

    if (cpfInput) {
        IMask(cpfInput, {
            mask: "000.000.000-00"
        })
    }
})

